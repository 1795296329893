@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Thin-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-ExtraLight-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Light-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Regular-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Medium-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-SemiBold-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Bold-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-ExtraBold-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: Raleway;
    src: url('fonts/Raleway-Black-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}
